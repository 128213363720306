import request from '@/utils/request'

// 查询总收益管理列表
export function listProblemTotalAmount(query) {
  return request({
    url: '/work/ProblemTotalAmount/list',
    method: 'get',
    params: query
  })
}

// 查询总收益管理详细
export function getProblemTotalAmount(id) {
  return request({
    url: '/work/ProblemTotalAmount/' + id,
    method: 'get'
  })
}

// 新增总收益管理
export function addProblemTotalAmount(data) {
  return request({
    url: '/work/ProblemTotalAmount',
    method: 'post',
    data: data
  })
}

// 修改总收益管理
export function updateProblemTotalAmount(data) {
  return request({
    url: '/work/ProblemTotalAmount/update',
    method: 'post',
    data: data
  })
}
// 计算收益
export function calculateIncome(data) {
  return request({
    url: '/work/ProblemTotalAmount/calculateIncome',
    method: 'post',
    data: data
  })
}
// 删除总收益管理
export function delProblemTotalAmount(id) {
  return request({
    url: '/work/ProblemTotalAmount/remove/' + id,
    method: 'get'
  })
}

// 导出总收益管理
export function exportProblemTotalAmount(query) {
  return request({
    url: '/work/ProblemTotalAmount/export',
    method: 'get',
    params: query
  })
}