<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="小程序id" prop="waxppId">
        <el-input
          v-model="queryParams.waxppId"
          placeholder="请输入小程序id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--          type="danger"-->
<!--          plain-->
<!--          icon="el-icon-delete"-->
<!--          size="mini"-->
<!--          :disabled="multiple"-->
<!--          @click="handleDelete"-->
<!--        >删除</el-button>-->
<!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="ProblemTotalAmountList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" width="100" prop="id" />
<!--      <el-table-column label="小程序id" align="center" prop="waxppId" />-->
      <el-table-column label="小程序" align="center"  width="100" prop="name" />
      <el-table-column label="总收益（更新前一天收益）" align="center" width="150" prop="incomeTotal" />

      <el-table-column label="日期" align="center" width="100" prop="createTime" />
<!--      <el-table-column label="创建者" align="center" prop="createBy" />-->
      <el-table-column label="操作" align="center" width="260" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              v-if="scope.row.realityEcpm==null"
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
              v-if="scope.row.realityEcpm==null&&scope.row.incomeTotal!=null"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="calculateIncome(scope.row)"
          >计算今日收益</el-button>
<!--          <el-button-->
<!--            size="mini"-->
<!--            type="text"-->
<!--            icon="el-icon-delete"-->
<!--            @click="handleDelete(scope.row)"-->
<!--          >删除</el-button>-->
        </template>
      </el-table-column>
      <el-table-column label="预估ecpm" align="center" width="100" prop="estimateEcpm" />
      <el-table-column label="实际ecpm" align="center" width="100" prop="realityEcpm" />
    </el-table>
    
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改总收益管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
<!--        <el-form-item label="小程序id" prop="waxppId">-->
<!--          <el-input v-model="form.waxppId" placeholder="请输入小程序id" />-->
<!--        </el-form-item>-->
        <el-form-item label="总收益" prop="incomeTotal">
          <el-input v-model="form.incomeTotal" placeholder="请输入总收益（请认真核对微信公众平台流量主）" />
        </el-form-item>
<!--        <el-form-item label="预估ecpm" prop="estimateEcpm">-->
<!--          <el-input v-model="form.estimateEcpm" placeholder="请输入预估ecpm" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="实际ecpm" prop="realityEcpm">-->
<!--          <el-input v-model="form.realityEcpm" placeholder="请输入实际ecpm" />-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listProblemTotalAmount, getProblemTotalAmount, delProblemTotalAmount, addProblemTotalAmount, updateProblemTotalAmount, exportProblemTotalAmount ,calculateIncome} from "@/api/work/ProblemTotalAmount";

export default {
  name: "ProblemTotalAmount",
  components: {
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 总收益管理表格数据
      ProblemTotalAmountList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 主键字典
      idOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        waxppId: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      }
    };
  },
  created() {
    this.getList();
    this.getDicts("${column.dictType}").then(response => {
      this.idOptions = response.data;
    });
  },
  methods: {
    /** 查询总收益管理列表 */
    getList() {
      this.loading = true;
      listProblemTotalAmount(this.queryParams).then(response => {
        this.ProblemTotalAmountList = response.datas;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 计算当日收益操作 */
    calculateIncome(row) {
      const id = row.id
      calculateIncome({"id":id}).then(response => {

      });
    },
    // 主键字典翻译
    idFormat(row, column) {
      return this.selectDictLabel(this.idOptions, row.id);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        waxppId: null,
        incomeTotal: null,
        estimateEcpm: null,
        realityEcpm: null,
        createTime: null,
        createBy: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加总收益管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getProblemTotalAmount(id).then(response => {
        this.form = response.datas;
        this.open = true;
        this.title = "修改总收益管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateProblemTotalAmount(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addProblemTotalAmount(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除总收益管理编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delProblemTotalAmount(ids);
        }).then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有总收益管理数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportProblemTotalAmount(queryParams);
        }).then(response => {
          this.download(response.msg);
        })
    }
  }
};
</script>
